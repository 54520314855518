import React from 'react';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';
import { Menu } from 'primereact/menu';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import DoubtsList from './doubtsList';
import { Toast } from 'primereact/toast';
import Service from './../discussionBoard/service';
import Common from './common';
import ReportDialog from './common/report';
import CONSTANTS from './common/constants';
import FileUpload from '../discussionBoard/common/fileUpload';
import {  StaticConstants } from './../../constants'
import { getFileType, getProfileURL, MESSAGE_STATUS } from './../../utile';
import PERM_CONSTANTS from './permissionConstants';
import { askADoubtEndpoint } from './../../store/apiConstants';
import LoadingComponent from './../loadingComponent';
import { AddIcon, ChatIcon, SendIcon, WarningIcon } from '../svgIcons';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { ProgressBar } from 'primereact/progressbar';
import _ from 'lodash';

const TEACHER_ID = localStorage.getItem('discussion_uId');
var timer1;
const CHAT_EVENTS = {
    selectSubject: 1,
    typeAChapter: 2,
    rating: 3,
    askADoubt: 4,
    areYouSatisfied: 5,
    closeChat: 6,
    notSatisfiedReason: 7,
    connectaNewTeacher: 8,
    thankAStudent: 9, //stop c from u & a
    reportedByTeacher: 10, // stop c from u & a
    requestedToClose: 11,
    reportThisDoubt: 12,
    doubtClosedByStudent: 13, // stop c from a
    requestedToANewTeacher: 14  // stop c from a
}



const isLocal = false;

class Conversation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            conversations: [],
            selectedDoubt: this.props.selectedDoubt,
            message: '',
            sort: '-createdAt',
            limit: 100,
            page: 1,
            search: '',
            discussions: [],
            stopRequests: false,
            isLoading: false,
            currentScrollPosition: 0,
            isResponded: false,
            showReport: false,
            moreOptionsRef: null,
            openMore: false,
            showAssignedDialog: false,
            stopConversation: false,
            currentSocketMessage: { eventUID: 0 },
            openAddFiles: false,
            uploadedFilesInfo: [], //{name :'dddddddfsdfdsfs', progressStatus:34}
            showFileUploadDialog: false,
            showAttachment: true,

            rows: 1,
            minRows: 1,
            maxRows: 5,
            style: ''
            // selectedAttachment: null

        };
        //  this.websocket = new WebSocket(askADoubtSocketEndpoint);
        this.service = new Service();
        this.messageEndRef = React.createRef();
        this.chatWidowRef = React.createRef();
    }

    getConversations = (doubtId, sort, limit, page, search, isNew) => {

        if (doubtId) {
            let url = `${askADoubtEndpoint}/doubts/${doubtId}/conversations?sort=${sort}&limit=${limit}&page=${page}&search=${search}`;
            this.service.get(url, true).then(data => {
                if (data.status) {
                    if (data.res && data.res.data && data.res.data.length) {
                        let conversations = isNew ? [] : this.state.conversations;
                        conversations = conversations.concat(data.res.data);
                        let stopConversation = this.state.stopConversation;
                        let i = conversations.length - 1;
                        let isReported = false;
                        for (i; i > 0; i--) {
                            if (conversations[i].currentRequest == CHAT_EVENTS.reportedByTeacher ||
                                conversations[i].currentRequest == CHAT_EVENTS.closeChat ||
                                conversations[i].currentRequest == CHAT_EVENTS.doubtClosedByStudent ||
                                conversations[i].currentRequest == CHAT_EVENTS.reportThisDoubt ||
                                (conversations[i].currentRequest == CHAT_EVENTS.requestedToANewTeacher && conversations[i].to._id == this.props.discussionUser._id)) {
                                stopConversation = true;
                                break;
                            }
                        }
                        this.setState({
                            conversations,
                            stopConversation,
                            selectedDoubt: { ...this.state.selectedDoubt, isReported: isReported, isDoubtCleared: isReported }
                        });
                    }
                }
            });
        }
    }

    componentDidMount() {
        let { sort, limit, page, search, selectedDoubt } = this.state;
        this.getConversations(selectedDoubt && selectedDoubt._id, sort, limit, page, search);

        let thisObj = this;
        timer1 = setInterval(() => {
            //   console.log('shashi');
            let { sort, limit, page, search, selectedDoubt } = thisObj.state;
            this.getConversations(selectedDoubt && selectedDoubt._id, sort, limit, page, search, true);
        }, 20000);
    }

    componentWillUnmount() {
        clearTimeout(timer1);
    }
    // onMessageRecieve = (response) => {
    //     if (this.state.selectedDoubt && response.type == 'doubt') {
    //         if (response.doubtId == this.state.selectedDoubt._id && response.responded) {
    //             let conversations = this.state.conversations;
    //             conversations = conversations.concat(response.message);
    //             this.setState({
    //                 conversations,
    //                 isResponded: true,
    //                 isLoading: false,
    //                 selectedDoubt: { ...this.state.selectedDoubt, isAssigned: true }
    //             }, () => {
    //                 // this.props.onDoubtRespond(1)
    //             });
    //         } else if (response.doubtId == this.state.selectedDoubt._id) {
    //             let conversations = this.state.conversations;
    //             conversations = conversations.concat(response.message);

    //             let stopConversation = false;
    //             if (response.message && response.message.length && (response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.doubtClosedByStudent ||
    //                 response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.reportedByTeacher ||
    //                 response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.closeChat ||
    //                 (response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.requestedToANewTeacher && response.message[response.message.length - 1].to._id == this.props.discussionUser._id)
    //             )) {
    //                 stopConversation = true;
    //             }

    //             this.setState({
    //                 conversations,
    //                 stopConversation,

    //             }, () => {
    //                 if (stopConversation) {
    //                     //  this.props.onDoubtRespond(2)
    //                 }
    //             });
    //         }
    //     } else if (response.type == 'deleteMessage') {
    //         if (response.doubtId == this.state.selectedDoubt._id) {
    //             let conversations = this.state.conversations.filter((c) => c._id !== response.conversationId);
    //             this.setState({
    //                 conversations,
    //             });
    //         }
    //     } else if (response.type == 'reported') {
    //         if (response.doubtId == this.state.selectedDoubt._id) {
    //             let conversations = this.state.conversations;
    //             conversations = conversations.concat(response.message);
    //             this.setState({
    //                 conversations,
    //                 selectedDoubt: { ...this.state.selectedDoubt, isReported: true, isDoubtCleared: true }
    //             }, () => {
    //                 //  this.props.onDoubtRespond(2)
    //             });
    //         }
    //     }
    // }

    acknowledgeMessage = (doubtId, conversations) => {
        let conversationIds = conversations.filter(c => {
            if (c.from._id !== this.props.discussionUser._id) {
                return c._id
            }
        });

        if (conversationIds && conversationIds.length) {
            let payload = {
                action: "seenMessage",
                data: {
                    doubtId: doubtId,
                    conversationIds: conversationIds.map((a) => { return a._id })
                },
                user: this.props.discussionUser._id,
                beacon: uuidv4()
            };
            this.socketSend(payload);
        }
    }

    onMessageRecieve = (response) => {
        if (this.state.selectedDoubt && response.type == 'doubt') {

            this.acknowledgeMessage(response.doubtId, response.message);

            if (response.doubtId == this.state.selectedDoubt._id && response.responded) {
                let conversations = this.state.conversations;
                conversations = conversations.concat(response.message);
                this.setState({
                    conversations,
                    isResponded: true,
                    isLoading: false,
                    selectedDoubt: { ...this.state.selectedDoubt, isAssigned: true }
                }, () => {
                    // this.props.onDoubtRespond(1)
                });



            } else if (response.doubtId == this.state.selectedDoubt._id) {
                let conversations = this.state.conversations;

                let isMyMessage = false;
                conversations.map((c) => {
                    if (c.beacon && c.beacon == response.beacon) {
                        c.status = MESSAGE_STATUS.sent;
                        isMyMessage = true;
                    }
                    return c;
                });


                if (isMyMessage) {

                    if (response.message && response.message.length) {

                        response.message.forEach((m) => {
                            if (!(m.beacon && m.beacon == response.beacon)) {
                                conversations = [...conversations, m];
                            }
                        });
                    }

                    //conversations = conversations.concat(response.message);
                } else {
                    conversations = conversations.concat(response.message);
                }





                let stopConversation = this.state.stopConversation;
                if (response.message && response.message.length && (response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.doubtClosedByStudent ||
                    response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.reportedByTeacher ||
                    response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.closeChat ||
                    (response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.requestedToANewTeacher && response.message[response.message.length - 1].to._id == this.props.discussionUser._id)
                )) {
                    stopConversation = true;
                }

                this.setState({
                    conversations,
                    stopConversation,

                }, () => {
                    if (stopConversation) {
                        //  this.props.onDoubtRespond(2)
                    }
                });
            }
        } else if (response.type == 'deleteMessage') {
            if (response.doubtId == this.state.selectedDoubt._id) {
                let conversations = this.state.conversations.filter((c) => c._id !== response.conversationId);
                this.setState({
                    conversations,
                });
            }
        } else if (response.type == 'reported') {
            if (response.doubtId == this.state.selectedDoubt._id) {
                let conversations = this.state.conversations;
                conversations = conversations.concat(response.message);
                this.setState({
                    conversations,
                    selectedDoubt: { ...this.state.selectedDoubt, isReported: true, isDoubtCleared: true }
                }, () => {
                    //  this.props.onDoubtRespond(2)
                });
            }

        } else if (response.type == 'acknowledgeReceived') {
            if (response.doubtId == this.state.selectedDoubt._id) {
                let conversations = this.state.conversations;

                for (let j = 0; j < response.conversationIds; j++) {
                    conversations = conversations.map((c) => {
                        if (c._id == response.conversationIds[j]) {
                            c.status = MESSAGE_STATUS.delivered;
                        }
                        return c;
                    });
                }

                this.setState({
                    conversations,
                    selectedDoubt: { ...this.state.selectedDoubt, isReported: true, isDoubtCleared: true }
                }, () => {
                    //  this.props.onDoubtRespond(2)
                });
            }
        } else if (response.type == 'acknowledgeSeen') {
            if (response.doubtId == this.state.selectedDoubt._id) {
                let conversations = this.state.conversations;

                for (let j = 0; j < response.conversationIds; j++) {
                    conversations = conversations.map((c) => {
                        if (c._id == response.conversationIds[j]) {
                            c.status = MESSAGE_STATUS.seen;
                        }
                        return c;
                    });
                }

                this.setState({
                    conversations,
                    selectedDoubt: { ...this.state.selectedDoubt, isReported: true, isDoubtCleared: true }
                }, () => {
                    //  this.props.onDoubtRespond(2)
                });
            }
        }
    }

    socketSend = (data) => {
        this.props.socketSend(data);
    }

    requestToCloseDoubt = () => {
        let payload = {
            action: "requestToCloseDoubt",
            data: {
                doubtId: this.state.selectedDoubt._id,
                from: this.props.discussionUser._id,
                to: this.state.selectedDoubt.askedBy._id
            },
            user: this.props.discussionUser._id,
            beacon: uuidv4()
        };

        this.setState({
            openMore: false
        });

        this.socketSend(payload);
    }

    requestToJoinMeeting = () => {
        let payload = {
            action: "requestToJoingMeeting",
            data: {
                doubtId: this.state.selectedDoubt._id,
                from: this.props.discussionUser._id,
                to: this.state.selectedDoubt.askedBy._id
            },
            user: this.props.discussionUser._id,
            beacon: uuidv4()
        };

        this.setState({
            openMore: false
        });

        this.socketSend(payload);
    }

    showReportDialog = () => {
        this.setState({
            openMore: false,
            showReport: true
        });
    }



    reportDoubt = (reason) => {
        let beacon = uuidv4();
        let payload = {
            action: "reportDoubt",
            data: {
                doubtId: this.state.selectedDoubt._id,
                from: this.props.discussionUser._id,
                to: this.state.selectedDoubt.askedBy._id,
                reason
            },
            user: this.props.discussionUser._id,
            beacon
        };

        this.setState({
            showReport: false
        });
        this.socketSend(payload);
    }

    onTextChange = (event) => {
        let e = event.currentTarget.value;

        const textareaLineHeight = 24;
        const { minRows, maxRows, chatWidowRef } = this.state;

        // const previousRows = event.target.rows;
        // event.target.rows = minRows; // reset number of rows in textarea 

        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        // if (currentRows === previousRows) {
        //     event.target.rows = currentRows;
        // }

        // if (currentRows >= maxRows) {
        //     event.target.rows = maxRows;
        //     event.target.scrollTop = event.target.scrollHeight;
        // }

        var elem = document.getElementById("chat-window");
        var theCSSprop = window.getComputedStyle(elem, null).getPropertyValue("min-height");
        theCSSprop = Number(theCSSprop.replace("px", ''));
        let dd = (currentRows != this.state.rows && currentRows <= maxRows) ? (theCSSprop - 24).toString() + "px" : this.state.style;

        //  console.log(currentRows)
        this.setState((prevState) => ({
            message: e,
            //rows:
            // errors: { ...prevState.errors, content: e.length < 10 ? true : false },
            rows: currentRows < maxRows ? currentRows : maxRows,
            style: dd
        }));



        if (currentRows != this.state.rows) {
            //   console.log("minHeight", dd)
            // document.getElementById("chat-window").style.minHeight = dd;
            // document.getElementById("chat-window").style.maxHeight = dd;

            // this.chatWidowRef.style.minHeight = dd;
            // this.chatWidowRef.style.maxHeight = dd;
        }

    }

    sendMessage = () => {
        if (this.state.message != '') {
            let beacon = uuidv4();
            let message = {
                type: 'text',
                content: this.state.message,
                from: this.props.discussionUser._id,
                to: this.state.selectedDoubt.askedBy._id,
                direction: 2,  //from user  to teacher 
                seen: false,
                beacon,
                status: MESSAGE_STATUS.sending
            };

            let payload = {
                action: "sendMessage",
                data: { conversation: message, doubtId: this.state.selectedDoubt._id },
                user: this.props.discussionUser._id,
                beacon
            };

            //console.log(payload)

            this.setState({
                message: '',
                rows: 1,
                conversations: [...this.state.conversations, message]
            });


            this.socketSend(payload);
        }

    }

    sendAttachment = (attachment) => {
        let beacon = uuidv4();
        let message = {
            type: getFileType(attachment.type),
            content: attachment.uri,
            from: this.props.discussionUser._id,
            to: this.state.selectedDoubt.askedBy._id,
            attachmentInfo: attachment,
            direction: 2,  //from user  to teacher 
            seen: false,
            beacon
        };

        let payload = {
            action: "sendMessage",
            data: { conversation: message, doubtId: this.state.selectedDoubt._id },
            user: this.props.discussionUser._id,
            beacon
        };

        this.socketSend(payload);

    }

    respondToDoubt = () => {
        if (!this.state.isLoading) {
            this.setState({
                isLoading: true
            });

            let url1 = isLocal ? 'http://localhost:3002/api/v1' : askADoubtEndpoint;
            let url = `${url1}/doubts/${this.state.selectedDoubt._id}/checkisAssigned`;
            this.service.get(url, true).then(data => {
                if (data.status) {
                    if (data.res && data.res) {
                        if (data.res.isAssigned) {
                            this.setState({
                                showAssignedDialog: true,
                                isLoading: false

                            });
                        } else {
                            let data = {
                                doubtId: this.state.selectedDoubt._id,
                                teacher: this.props.discussionUser._id,
                                user: this.state.selectedDoubt.askedBy._id
                            };

                            let payload = {
                                action: "respondDoubt",
                                data,
                                user: this.props.discussionUser._id,
                            };

                            this.socketSend(payload);
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        })

                    }
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
        }


    }

    deleteMessage = (conversation) => {
        let message = {
            conversationId: conversation._id,
            to: conversation.to._id,
            from: conversation.from._id
        };

        let payload = {
            action: "deleteMessage",
            data: { conversation: message, doubtId: this.state.selectedDoubt._id },
            user: this.props.discussionUser._id,
        };


        this.socketSend(payload);



    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom = () => {

        if (this.messageEndRef && this.messageEndRef.current) {
            this.messageEndRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    connectToOnline = () => {
        let payload = {
            action: "online",
            data: {
                user: this.props.discussionUser._id,
                isOnline: true
            },
        };
        this.socketSend(payload)
    }

    deleteConversation = (conversation) => {
        let payload = {
            action: "deleteConversation",
            data: { conversationId: conversation._id, to: conversation.to, from: conversation.from },
            user: this.props.discussionUser._id,
        };

        this.socketSend(payload);

    }

    onMoreClick = (event) => {
        this.setState({
            openMore: !this.state.openMore,
            moreOptionsRef: event.currentTarget
        });

    }

    onAttachClick = (event) => {
        if ((this.state.selectedDoubt && this.state.selectedDoubt.isAssigned && this.props.discussionUser._id == this.state.selectedDoubt.assignedTo) || this.state.isResponded) {
            this.setState({
                openAddFiles: !this.state.openAddFiles,
                addFilesRef: event.currentTarget
            });
        }
    }

    onDialogClose = () => {
        this.setState({
            showAssignedDialog: false
        });
    }


    _onProgress = (filesInfo) => {
        let files = this.state.uploadedFilesInfo;
        files = files.map((file) => {
            if (file.name == filesInfo.name) {
                file = filesInfo;
            }
            return file;
        })

        this.setState((prevState) => {
            return { uploadedFilesInfo: files }
        });
    }


    _onFilsUploadError = (errMsg) => {
        // this.setState({
        //     snackBarMsg: errMsg,
        //     snackBarOpen: true
        // });

        this.toast.show({ severity: 'error', summary: 'File Upload Error', detail: errMsg, life: 3000 });
    }


    _afterFilesUploaded = (filess) => {

        let files = _.cloneDeep(Array.from(filess));
        for (let i = 0; i < files.length; i++) {
            this.setState((prevState, prevProps) => ({
                openAddFiles: false,
                uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]],
                showFileUploadDialog: true
            }));
        }
    }

    _onSuccess = (fileName, data) => {

        let files = this.state.uploadedFilesInfo;
        files = files.map((file) => {
            if (file.name == fileName) {
                file.s3Info = data;
                file.uri = data.Location;
            }
            return file;
        })
        let attachment = files[0];
        this.setState((prevState) => {
            return {
                uploadedFilesInfo: [],
                showFileUploadDialog: false,

            }
        }, () => { this.sendAttachment(attachment) });
    }

    removeAttachment = (attachment) => {
        let uploadedFilesInfo = this.state.uploadedFilesInfo.filter(a => a.name != attachment.name);
        this.setState({ uploadedFilesInfo });
    }

    showAttachment = (a) => {
        if (a.uri) {
            this.setState({
                showAttachment: true,
                //  selectedAttachment: a
            });
        }
    }

    getView = (attachment) => {
        return <div className="ma-attachment">

            <div>
                <div style={{ width: '100%', overflow: 'hidden' }} onClick={() => { this.showAttachment(attachment) }}>
                    <div style={{ marginLeft: '20px' }}>
                        <p> {attachment.name} {attachment.progressStatus < 100 && <ProgressBar style={{ height: '6px' }} value={attachment.progressStatus}></ProgressBar>} </p>



                    </div>
                </div>
            </div>
        </div>
    }


    componentWillReceiveProps(newProps) {
        let { sort, limit, page, search } = this.state;
        if (!this.state.selectedDoubt && newProps.selectedDoubt) {
            this.setState({
                selectedDoubt: newProps.selectedDoubt,
                isResponded: false,
                stopConversation: false,
                openMore: false,
                openAddFiles: false,
                uploadedFilesInfo: [],
                showFileUploadDialog: false,
            }, () => { this.getConversations(newProps.selectedDoubt._id, sort, limit, page, search, true) });

        } else if (this.state.selectedDoubt && newProps.selectedDoubt && (this.state.selectedDoubt._id !== newProps.selectedDoubt._id)) {
            this.setState({
                selectedDoubt: newProps.selectedDoubt,
                stopConversation: false,
                isResponded: false,
                openMore: false,
                openAddFiles: false,
                uploadedFilesInfo: [],
                showFileUploadDialog: false,
            }, () => { this.getConversations(newProps.selectedDoubt._id, sort, limit, page, search, true) });
        }

        if (newProps.currentSocketMessage) {
            if (this.state.currentSocketMessage.eventUID != newProps.currentSocketMessage.eventUID) {
                this.setState({
                    currentSocketMessage: newProps.currentSocketMessage
                }, () => { this.onMessageRecieve(newProps.currentSocketMessage) });
            }
        }

    }

    render() {
        const { selectedDoubt } = this.state;

        const items = [];


        if ((!(this.state.selectedDoubt && this.state.selectedDoubt.isReported || this.state.stopConversation) && this.state.selectedDoubt && this.state.selectedDoubt.isAssigned)) {
            items.push({
                template: (< span className="ma-pointer" onClick={(event) => { this.requestToCloseDoubt(); this.menu.toggle(event) }} > Request to close this Doubt</span >)
            },
                {
                    template: (<span className="ma-pointer" onClick={(event) => { this.showReportDialog(); this.menu.toggle(event) }}>Report this Doubt</span>)
                },
                {
                    template: (<span className="ma-pointer" onClick={(event) => { this.requestToJoinMeeting(); this.menu.toggle(event) }}>Request to Join the Live Call</span>)
                },
            )

        }
        //   console.log(this.props.discussionUser);
        return (
            <>
                <div className="conversation-c">
                    <>
                        {!selectedDoubt ? <div className="select-profile-c"> <div className="select-profile">

                            <ChatIcon height={200} width={200} color='var(--theme-45)' />
                            <p className="select-profile-txt">Select any profile to give answers to the Doubts</p>
                        </div> </div> : <>
                            <div>
                                {
                                    selectedDoubt && <div className="c-top-bar">
                                        <div className="chat-topnar">
                                            <div className="ma-1">
                                                <img src={getProfileURL(selectedDoubt?.askedBy?.photo)} className="prfl-img" />
                                            </div>
                                            <div className="ma-1 ma-ml10">
                                                <p className="prfl-name">{selectedDoubt.askedBy.name}</p>
                                                {/* <p className="prfl-grade"  >{'Class XII , Engineering (CBSC)'}</p> */}
                                            </div>
                                            <div className="ma-right">
                                                {(!(this.state.selectedDoubt.isReported || this.state.stopConversation) && this.state.selectedDoubt.isAssigned) && <span><i className="pi pi-ellipsis-v ma-pointer"
                                                    //onClick={this.onMoreClick}
                                                    onClick={(event) => this.menu.toggle(event)}
                                                /></span>}

                                                <Menu
                                                    className='answer-menu'
                                                    model={
                                                        items}
                                                    popup ref={el => this.menu = el} />
                                            </div>
                                        </div>
                                        <div className="ma-clearFix">
                                        </div>
                                    </div>
                                }
                                <div id="chat-window" ref={this.chatWidowRef} className="chat-window ma-scroll-vr-smoke"
                                    style={this.state.rows > 1 ? { minHeight: this.state.style, maxHeight: this.state.style } : {}}
                                >
                                    {this.state.conversations.map((c, i) => {
                                        return <div key={i.toString()}>
                                            {<Common discussionUser={this.props.discussionUser} conversation={c} deleteMessage={this.deleteMessage} />}
                                            <div className="ma-clearFix"></div>
                                        </div>
                                    })}
                                    <div ref={this.messageEndRef} />
                                </div>
                                {(this.state.selectedDoubt.isReported || this.state.stopConversation) ? <div className="closed-chat">
                                    {this.state.selectedDoubt.isReported ? <p>Doubt is reported</p> : <p className="">Chat is closed</p>}
                                </div> : <div className="p-grid ma-no-pm chat-typing">
                                    <div className='p-col-12'>
                                        <div style={{ width: '100%', overflow: 'hidden' }}>
                                            <div style={{ width: '40px', float: 'left' }}>
                                                <Authorizer permId={PERMISSIONS.ASK_A_DOUBT_GENERAL_UPLOAD_ATTACHMENT}  >
                                                    <span className='ma-pointer' onClick={(event) => this.menu2.toggle(event)} >
                                                        <AddIcon width={30} height={30} color='var(--primary-color)' style={{ marginTop: '4px' }} />
                                                    </span>
                                                </Authorizer>
                                                <Menu
                                                    className='answer-menu'
                                                    model={
                                                        [
                                                            {
                                                                template: (<>
                                                                    <FileUpload id={"imagesd"}
                                                                        multiple={false}
                                                                        onProgress={this._onProgress}
                                                                        onSucess={this._onSuccess}
                                                                        Reset={this.state.Reset}
                                                                        maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                                                        onError={this._onFilsUploadError}
                                                                        accept={'imagevideo'}
                                                                        title={'add image or video'}
                                                                        labelStyles={{ display: 'block' }}
                                                                        menuRef={this.menu2}
                                                                        afterFilesUploaded={this._afterFilesUploaded} >
                                                                        <span className="ma-pointer"

                                                                        // onClick={(event) => { this.menu2.toggle(event) }}
                                                                        >
                                                                            <img className="add-file-attach ma-pointer" src="./images/Photo_video_icon.svg" /> Photos & Videos</span>
                                                                    </FileUpload>
                                                                </>)
                                                            },
                                                            {
                                                                template: (<>{<FileUpload id={'pdfss'}
                                                                    multiple={false}
                                                                    onProgress={this._onProgress}
                                                                    onSucess={this._onSuccess}
                                                                    Reset={this.state.Reset}
                                                                    maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                                                    onError={this._onFilsUploadError}
                                                                    accept={'pdfs'}
                                                                    title={'add pdf'}
                                                                    menuRef={this.menu2}
                                                                    labelStyles={{ display: 'block' }}
                                                                    afterFilesUploaded={this._afterFilesUploaded} >
                                                                    <span className="ma-pointer"
                                                                    //  onClick={() => {
                                                                    //     setTimeout(() => {
                                                                    //         this.setState({ openAddFiles: false })
                                                                    //     }, 5000);
                                                                    // }}
                                                                    //  onClick={(event) => { this.menu2.toggle(event) }}
                                                                    >
                                                                        <img className="add-file-attach ma-pointer" src="./images/PDF_Doc_icon.svg" /> PDF's & Documents
                                                                    </span>
                                                                </FileUpload>}</>)
                                                            }
                                                        ]}
                                                    popup ref={el => this.menu2 = el} />
                                            </div>
                                            <div style={{ marginLeft: '40px', display: 'flex' }}>
                                                <textarea
                                                    disabled={(this.state.selectedDoubt.isReported || this.state.stopConversation || !(this.state.selectedDoubt && this.state.selectedDoubt.isAssigned))}
                                                    className="chat-textarea"
                                                    //rows="1"
                                                    rows={this.state.rows}
                                                    value={this.state.message}

                                                    onChange={this.onTextChange} />
                                                {((this.state.selectedDoubt && this.state.selectedDoubt.isAssigned && this.props.discussionUser._id == this.state.selectedDoubt.assignedTo) || this.state.isResponded) ?
                                                    <>
                                                        {!(this.state.selectedDoubt.isReported || this.state.stopConversation) && <span className='ma-pointer' onClick={this.sendMessage}>
                                                            <SendIcon width={34} height={34} color="var(--primary-color)" />

                                                        </span>


                                                        }
                                                    </> : <>
                                                        <Authorizer permId={PERMISSIONS.ASK_A_DOUBT_GENERAL_ATTEMPT_DOUBT}  >
                                                            <div className="respond-now-w">
                                                                <button disabled={this.state.isLoading} className="respond-now" onClick={() => { this.respondToDoubt() }}>Respond Now</button>
                                                            </div>
                                                        </Authorizer>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>}

                                <Dialog
                                    blockScroll={true}
                                    visible={this.state.showAssignedDialog}
                                    onHide={() => { this.setState({ showAssignedDialog: false }) }}
                                    closable={false}
                                    showHeader={true}
                                    footer={() => {
                                        return (<div>
                                            <Button label="Ok" className='ma-m-lr10'
                                                onClick={this.onDialogClose}
                                            />
                                        </div>)
                                    }}
                                >
                                    
                                    <span  className="warning-icon" >
              <WarningIcon width={"100%"} height={"100%"} />
            </span>
                                    <p className="warning-title">It is already assigned to some other teacher.</p>
                                </Dialog>
                                <Dialog
                                    blockScroll={true}
                                    visible={this.state.showFileUploadDialog}
                                    closable={false}
                                    showHeader={true}
                                // footer={() => {
                                //     return (<div>
                                //         <Button label="Ok" className='ma-m-lr10'
                                //             onClick={this.onDialogClose}
                                //         />
                                //     </div>)
                                // }}
                                >
                                    <p className="warning-title">Please wait the file is Uploading....</p>
                                    {(this.state.uploadedFilesInfo && this.state.uploadedFilesInfo.length) ? this.getView(this.state.uploadedFilesInfo[0]) : null}
                                </Dialog>
                            </div>
                        </>}
                    </>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.showReport && <ReportDialog open={true} onSubmit={this.reportDoubt} onClose={() => { this.setState({ showReport: false }) }} />}
                {
                    this.state.isLoading &&
                    <LoadingComponent />
                }
            </>
        );
    }
}

const mapStatesToProps = (state) => ({
    discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
});

export default connect(mapStatesToProps, {})(Conversation);
