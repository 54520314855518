import React from "react";
import uuidv4 from "uuid/v4";
import { connect } from "react-redux";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import DoubtsList from "./doubtsList";
import { Toast } from "primereact/toast";
import Service from "../discussionBoard/service";
import Common from "./common";
import ReportDialog from "./common/report";
import CONSTANTS from "./common/constants";
import FileUpload from "../discussionBoard/common/fileUpload";
import {  StaticConstants } from "../../constants";
import { getFileType, getProfileURL, MESSAGE_STATUS } from "../../utile";
import PERM_CONSTANTS from "./permissionConstants";
import {
  askADoubtEndpoint,
  ticketMangementEndpoint,
} from "../../store/apiConstants";
import LoadingComponent from "../loadingComponent";
import { AddIcon, ChatIcon, SendIcon, WarningIcon } from "../svgIcons";
import Authorizer, { PERMISSIONS } from "../session/authorizer";
import { ProgressBar } from "primereact/progressbar";
import _ from "lodash";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

const statusOPtions = [
  { label: "Opened", value: "NEW" },
  { label: "In Progress", value: "INPROGRESS" },
  { label: "Closed", value: "CLOSED" },
];

const levelOPtions = [
  { label: "Level 1", value: "1" },
  { label: "Level 2", value: "2" },
  { label: "Level 3", value: "3" },
];

const TEACHER_ID = localStorage.getItem("discussion_uId");
var timer1;
const CHAT_EVENTS = {
  selectSubject: 1,
  typeAChapter: 2,
  rating: 3,
  askADoubt: 4,
  areYouSatisfied: 5,
  closeChat: 6,
  notSatisfiedReason: 7,
  connectaNewTeacher: 8,
  thankAStudent: 9, //stop c from u & a
  reportedByTeacher: 10, // stop c from u & a
  requestedToClose: 11,
  reportThisDoubt: 12,
  doubtClosedByStudent: 13, // stop c from a
  requestedToANewTeacher: 14, // stop c from a
};

const isLocal = false;

class Conversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conversations: [],
      selectedDoubt: this.props.selectedDoubt,
      message: "",
      sort: "-createdAt",
      limit: 100,
      page: 1,
      search: "",
      discussions: [],
      stopRequests: false,
      isLoading: false,
      currentScrollPosition: 0,
      isResponded: false,
      showReport: false,
      moreOptionsRef: null,
      openMore: false,
      showAssignedDialog: false,
      stopConversation: false,
      currentSocketMessage: { eventUID: 0 },
      openAddFiles: false,
      uploadedFilesInfo: [], //{name :'dddddddfsdfdsfs', progressStatus:34}
      showFileUploadDialog: false,
      showAttachment: true,

      rows: 1,
      minRows: 1,
      maxRows: 5,
      style: "",
      currentTab: this.props.currentTab,
      selectedStatus: null,
      statusModal: false,
      levelModal: false,
      selectedLevel: null,
      dateModal: false,
      selectedDeadlineDate: null,
      selectTicketStatus: null,
      // selectedAttachment: null
    };
    //  this.websocket = new WebSocket(askADoubtSocketEndpoint);
    this.service = new Service();
    this.messageEndRef = React.createRef();
    this.chatWidowRef = React.createRef();
    this.role = localStorage.getItem("name");
  }

  getConversations = (doubtId, sort, limit, page, search, isNew) => {
    if (doubtId) {
      let url = `${ticketMangementEndpoint}/tickets/${doubtId}/conversations?sort=${sort}&limit=${limit}&page=${page}&search=${search}`;
      this.service.get(url, true).then((data) => {
        if (data.status) {
          if (data.res && data.res.data && data.res.data.length) {
            let conversations = isNew
              ? []
              : _.cloneDeep(this.state.conversations);
            conversations = conversations.concat(data.res.data);
            let stopConversation = this.state.stopConversation;
            let i = conversations.length - 1;
            let isReported = false;
            for (i; i > 0; i--) {
              conversations[i].direction =
                conversations[i]?.from?.name?.toLowerCase() ===
                  this?.role?.toLowerCase()
                  ? 2
                  : 1;
              if (
                conversations[i].currentRequest ==
                CHAT_EVENTS.reportedByTeacher ||
                conversations[i].currentRequest == CHAT_EVENTS.closeChat ||
                conversations[i].currentRequest ==
                CHAT_EVENTS.doubtClosedByStudent ||
                conversations[i].currentRequest ==
                CHAT_EVENTS.reportThisDoubt ||
                (conversations[i].currentRequest ==
                  CHAT_EVENTS.requestedToANewTeacher &&
                  conversations[i].to._id == this.props.discussionUser._id)
              ) {
                stopConversation = true;
                break;
              }
            }
            this.setState({
              conversations,
              stopConversation,
              selectedDoubt: {
                ...this.state.selectedDoubt,
                isReported: isReported,
                isDoubtCleared: isReported,
              },
            });
          }
        }
      });
    }
  };

  componentDidMount() {
    let { sort, limit, page, search, selectedDoubt } = this.state;
    this.getConversations(
      selectedDoubt && selectedDoubt._id,
      sort,
      limit,
      page,
      search
    );

    let thisObj = this;
    timer1 = setInterval(() => {
      //   console.log('shashi');
      let { sort, limit, page, search, selectedDoubt } = thisObj.state;
      this.getConversations(
        selectedDoubt && selectedDoubt._id,
        sort,
        limit,
        page,
        search,
        true
      );
    }, 20000);
  }

  componentWillUnmount() {
    clearTimeout(timer1);
  }
  // onMessageRecieve = (response) => {
  //     if (this.state.selectedDoubt && response.type == 'doubt') {
  //         if (response.doubtId == this.state.selectedDoubt._id && response.responded) {
  //             let conversations = this.state.conversations;
  //             conversations = conversations.concat(response.message);
  //             this.setState({
  //                 conversations,
  //                 isResponded: true,
  //                 isLoading: false,
  //                 selectedDoubt: { ...this.state.selectedDoubt, isAssigned: true }
  //             }, () => {
  //                 // this.props.onDoubtRespond(1)
  //             });
  //         } else if (response.doubtId == this.state.selectedDoubt._id) {
  //             let conversations = this.state.conversations;
  //             conversations = conversations.concat(response.message);

  //             let stopConversation = false;
  //             if (response.message && response.message.length && (response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.doubtClosedByStudent ||
  //                 response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.reportedByTeacher ||
  //                 response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.closeChat ||
  //                 (response.message[response.message.length - 1].currentRequest == CHAT_EVENTS.requestedToANewTeacher && response.message[response.message.length - 1].to._id == this.props.discussionUser._id)
  //             )) {
  //                 stopConversation = true;
  //             }

  //             this.setState({
  //                 conversations,
  //                 stopConversation,

  //             }, () => {
  //                 if (stopConversation) {
  //                     //  this.props.onDoubtRespond(2)
  //                 }
  //             });
  //         }
  //     } else if (response.type == 'deleteMessage') {
  //         if (response.doubtId == this.state.selectedDoubt._id) {
  //             let conversations = this.state.conversations.filter((c) => c._id !== response.conversationId);
  //             this.setState({
  //                 conversations,
  //             });
  //         }
  //     } else if (response.type == 'reported') {
  //         if (response.doubtId == this.state.selectedDoubt._id) {
  //             let conversations = this.state.conversations;
  //             conversations = conversations.concat(response.message);
  //             this.setState({
  //                 conversations,
  //                 selectedDoubt: { ...this.state.selectedDoubt, isReported: true, isDoubtCleared: true }
  //             }, () => {
  //                 //  this.props.onDoubtRespond(2)
  //             });
  //         }
  //     }
  // }

  acknowledgeMessage = (doubtId, conversations) => {
    let conversationIds = conversations.filter((c) => {
      if (c.from._id !== this.props.discussionUser._id) {
        return c._id;
      }
    });

    if (conversationIds && conversationIds.length) {
      let payload = {
        action: "seenMessage",
        data: {
          doubtId: doubtId,
          conversationIds: conversationIds.map((a) => {
            return a._id;
          }),
        },
        user: this.props.discussionUser._id,
        beacon: uuidv4(),
      };
      this.socketSend(payload);
    }
  };

  onMessageRecieve = (response) => {
    if (this.state.selectedDoubt && response.type == "doubt") {
      this.acknowledgeMessage(response.doubtId, response.message);

      if (
        response.doubtId == this.state.selectedDoubt._id &&
        response.responded
      ) {
        let conversations = this.state.conversations;
        conversations = conversations.concat(response.message);
        this.setState(
          {
            conversations,
            isResponded: true,
            isLoading: false,
            selectedDoubt: { ...this.state.selectedDoubt, isAssigned: true },
          },
          () => {
            // this.props.onDoubtRespond(1)
          }
        );
      } else if (response.doubtId == this.state.selectedDoubt._id) {
        let conversations = this.state.conversations;

        let isMyMessage = false;
        conversations.map((c) => {
          if (c.beacon && c.beacon == response.beacon) {
            c.status = MESSAGE_STATUS.sent;
            isMyMessage = true;
          }
          return c;
        });

        if (isMyMessage) {
          if (response.message && response.message.length) {
            response.message.forEach((m) => {
              if (!(m.beacon && m.beacon == response.beacon)) {
                conversations = [...conversations, m];
              }
            });
          }

          //conversations = conversations.concat(response.message);
        } else {
          conversations = conversations.concat(response.message);
        }

        let stopConversation = this.state.stopConversation;
        if (
          response.message &&
          response.message.length &&
          (response.message[response.message.length - 1].currentRequest ==
            CHAT_EVENTS.doubtClosedByStudent ||
            response.message[response.message.length - 1].currentRequest ==
            CHAT_EVENTS.reportedByTeacher ||
            response.message[response.message.length - 1].currentRequest ==
            CHAT_EVENTS.closeChat ||
            (response.message[response.message.length - 1].currentRequest ==
              CHAT_EVENTS.requestedToANewTeacher &&
              response.message[response.message.length - 1].to._id ==
              this.props.discussionUser._id))
        ) {
          stopConversation = true;
        }

        this.setState(
          {
            conversations,
            stopConversation,
          },
          () => {
            if (stopConversation) {
              //  this.props.onDoubtRespond(2)
            }
          }
        );
      }
    } else if (response.type == "deleteMessage") {
      if (response.doubtId == this.state.selectedDoubt._id) {
        let conversations = this.state.conversations.filter(
          (c) => c._id !== response.conversationId
        );
        this.setState({
          conversations,
        });
      }
    } else if (response.type == "reported") {
      if (response.doubtId == this.state.selectedDoubt._id) {
        console.log("444444444444444",response.message);
        let conversations = this.state.conversations;
        conversations = conversations.concat(response.message);
        this.setState(
          {
            conversations,
            selectedDoubt: {
              ...this.state.selectedDoubt,
              isReported: false,
              isDoubtCleared: true,
            },
          },
          () => {
            //  this.props.onDoubtRespond(2)
          }
        );
      }
    } else if (response.type == "acknowledgeReceived") {
      if (response.doubtId == this.state.selectedDoubt._id) {
        let conversations = this.state.conversations;
        console.log("555555555555555");

        for (let j = 0; j < response.conversationIds; j++) {
          conversations = conversations.map((c) => {
            if (c._id == response.conversationIds[j]) {
              c.status = MESSAGE_STATUS.delivered;
            }
            return c;
          });
        }

        this.setState(
          {
            conversations,
            selectedDoubt: {
              ...this.state.selectedDoubt,
              isReported: false,
              isDoubtCleared: true,
            },
          },
          () => {
            //  this.props.onDoubtRespond(2)
          }
        );
      }
    } else if (response.type == "acknowledgeSeen") {
      if (response.doubtId == this.state.selectedDoubt._id) {
        let conversations = this.state.conversations;
        console.log("66666666666666666");

        for (let j = 0; j < response.conversationIds; j++) {
          conversations = conversations.map((c) => {
            if (c._id == response.conversationIds[j]) {
              c.status = MESSAGE_STATUS.seen;
            }
            return c;
          });
        }

        this.setState(
          {
            conversations,
            selectedDoubt: {
              ...this.state.selectedDoubt,
              isReported: true,
              isDoubtCleared: true,
            },
          },
          () => {
            //  this.props.onDoubtRespond(2)
          }
        );
      }
    }
  };

  socketSend = (data) => {
    this.props.socketSend(data);
  };

  requestToCloseDoubt = () => {
    let payload = {
      action: "requestToCloseTicket",
      data: {
        doubtId: this.state.selectedDoubt._id,
        from: this.props.discussionUser._id,
        to: this.state.selectedDoubt.askedBy._id,
      },
      user: this.props.discussionUser._id,
      beacon: uuidv4(),
    };
    this.setState({
      openMore: false,
    });
    this.socketSend(payload);
  };

  changeTicketStatus = () => {
    if (this?.state?.selectedDoubt?.ticketLevel >= 1 && this?.state?.selectedDoubt?.expectedDeadline) {
      let payload = {
        action: "changeTicketStatus",
        data: {
          doubtId: this.state.selectedDoubt._id,
          from: this.props.discussionUser._id,
          to: this.state.selectedDoubt.askedBy._id,
          status: this.state.selectTicketStatus
        },
        user: this.props.discussionUser._id,
        beacon: uuidv4(),
      };
      this.setState({
        statusModal: false,
        selectedStatus: null
      });
      this.socketSend(payload);
      this.props.onCallRefreshTicketList(true)
    } else {
      this.toast.show({
        severity: "error",
        summary: "Select Level Error",
        detail: "Please add level",
        life: 3000,
      })
    }
  };

  requestToJoinMeeting = () => {
    let payload = {
      action: "requestToJoingMeeting",
      data: {
        doubtId: this.state.selectedDoubt._id,
        from: this.props.discussionUser._id,
        to: this.state.selectedDoubt.askedBy._id,
      },
      user: this.props.discussionUser._id,
      beacon: uuidv4(),
    };

    this.setState({
      openMore: false,
    });
    this.socketSend(payload);
  };

  showReportDialog = () => {
    this.setState({
      openMore: false,
      showReport: true,
    });
  };

  reportDoubt = (reason) => {
    let beacon = uuidv4();
    let payload = {
      action: "reportTicket",
      data: {
        doubtId: this.state.selectedDoubt._id,
        from: this.props.discussionUser._id,
        to: this.state.selectedDoubt.askedBy._id,
        reason,
      },
      user: this.props.discussionUser._id,
      beacon,
    };

    this.setState({
      showReport: false,
    });
    this.socketSend(payload);
  };

  onTextChange = (event) => {
    let e = event.currentTarget.value;

    const textareaLineHeight = 24;
    const { minRows, maxRows, chatWidowRef } = this.state;

    // const previousRows = event.target.rows;
    // event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    // if (currentRows === previousRows) {
    //     event.target.rows = currentRows;
    // }

    // if (currentRows >= maxRows) {
    //     event.target.rows = maxRows;
    //     event.target.scrollTop = event.target.scrollHeight;
    // }

    var elem = document.getElementById("chat-window");
    var theCSSprop = window
      .getComputedStyle(elem, null)
      .getPropertyValue("min-height");
    theCSSprop = Number(theCSSprop.replace("px", ""));
    let dd =
      currentRows != this.state.rows && currentRows <= maxRows
        ? (theCSSprop - 24).toString() + "px"
        : this.state.style;

    //  console.log(currentRows)
    this.setState((prevState) => ({
      message: e,
      //rows:
      // errors: { ...prevState.errors, content: e.length < 10 ? true : false },
      rows: currentRows < maxRows ? currentRows : maxRows,
      style: dd,
    }));

    if (currentRows != this.state.rows) {
      //   console.log("minHeight", dd)
      // document.getElementById("chat-window").style.minHeight = dd;
      // document.getElementById("chat-window").style.maxHeight = dd;
      // this.chatWidowRef.style.minHeight = dd;
      // this.chatWidowRef.style.maxHeight = dd;
    }
  };

  sendMessage = () => {
    if (this.state.message != "") {
      let beacon = uuidv4();
      let message = {
        type: "text",
        content: this.state.message,
        from: this.props.discussionUser._id,
        to: this.state.selectedDoubt.askedBy._id,
        direction: 2, //from user  to teacher
        seen: false,
        beacon,
        status: MESSAGE_STATUS.sending,
      };
      message.direction =
        message?.from?.name?.toLowerCase() === this?.role?.toLowerCase()
          ? 1
          : 2;
      let payload = {
        action: "sendMessage",
        data: { conversation: message, doubtId: this.state.selectedDoubt._id },
        user: this.props.discussionUser._id,
        beacon,
      };

      //console.log(payload)
      let { sort, limit, page, search } = this.state;

      // this.setState({
      //     message: '',
      //     rows: 1,
      //     conversations: [...this.state.conversations, message]
      // }, () => this.getConversations(this.state.selectedDoubt && this.state.selectedDoubt._id, sort, limit, page, search, true));
      this.setState({
        message: "",
        rows: 1,
        conversations: [...this.state.conversations, message],
      });

      this.socketSend(payload);
    }
  };

  sendAttachment = (attachment) => {
    let beacon = uuidv4();
    let message = {
      type: getFileType(attachment.type),
      content: attachment.uri,
      from: this.props.discussionUser._id,
      to: this.state.selectedDoubt.askedBy._id,
      attachmentInfo: attachment,
      direction: 2, //from user  to teacher
      seen: false,
      beacon,
    };

    let payload = {
      action: "sendMessage",
      data: { conversation: message, doubtId: this.state.selectedDoubt._id },
      user: this.props.discussionUser._id,
      beacon,
    };

    this.socketSend(payload);
  };

  respondToDoubt = () => {
    if (!this.state.isLoading) {
      this.setState({
        isLoading: true,
      });

      let url1 = isLocal
        ? "http://localhost:3002/api/v1"
        : ticketMangementEndpoint;
      let url = `${url1}/tickets/${this.state.selectedDoubt._id}/checkisAssigned`;
      this.service.get(url, true).then((data) => {
        if (data.status) {
          if (data.res && data.res) {
            if (data.res.isAssigned) {
              this.setState({
                showAssignedDialog: true,
                isLoading: false,
              });
            } else {
              let data = {
                doubtId: this.state.selectedDoubt._id,
                teacher: this.props.discussionUser._id,
                user: this.state.selectedDoubt.askedBy._id,
              };

              let payload = {
                action: "respondTicket",
                data,
                user: this.props.discussionUser._id,
              };

              this.socketSend(payload);
            }
          } else {
            this.setState({
              isLoading: false,
            });
          }
        } else {
          this.setState({
            isLoading: false,
          });
        }
      });
    }
  };

  deleteMessage = (conversation) => {
    let message = {
      conversationId: conversation._id,
      to: conversation.to._id,
      from: conversation.from._id,
    };

    let payload = {
      action: "deleteMessage",
      data: { conversation: message, doubtId: this.state.selectedDoubt._id },
      user: this.props.discussionUser._id,
    };

    this.socketSend(payload);
  };

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messageEndRef && this.messageEndRef.current) {
      this.messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  connectToOnline = () => {
    let payload = {
      action: "online",
      data: {
        user: this.props.discussionUser._id,
        isOnline: true,
      },
    };
    this.socketSend(payload);
  };

  deleteConversation = (conversation) => {
    let payload = {
      action: "deleteConversation",
      data: {
        conversationId: conversation._id,
        to: conversation.to,
        from: conversation.from,
      },
      user: this.props.discussionUser._id,
    };

    this.socketSend(payload);
  };

  onMoreClick = (event) => {
    this.setState({
      openMore: !this.state.openMore,
      moreOptionsRef: event.currentTarget,
    });
  };

  onAttachClick = (event) => {
    if (
      (this.state.selectedDoubt &&
        this.state.selectedDoubt.isAssigned &&
        this.props.discussionUser._id == this.state.selectedDoubt.assignedTo) ||
      this.state.isResponded
    ) {
      this.setState({
        openAddFiles: !this.state.openAddFiles,
        addFilesRef: event.currentTarget,
      });
    }
  };

  onDialogClose = () => {
    this.setState({
      showAssignedDialog: false,
    });
  };

  _onProgress = (filesInfo) => {
    let files = this.state.uploadedFilesInfo;
    files = files.map((file) => {
      if (file.name == filesInfo.name) {
        file = filesInfo;
      }
      return file;
    });

    this.setState((prevState) => {
      return { uploadedFilesInfo: files };
    });
  };

  _onFilsUploadError = (errMsg) => {
    // this.setState({
    //     snackBarMsg: errMsg,
    //     snackBarOpen: true
    // });

    this.toast.show({
      severity: "error",
      summary: "File Upload Error",
      detail: errMsg,
      life: 3000,
    });
  };

  _afterFilesUploaded = (filess) => {
    let files = _.cloneDeep(Array.from(filess));
    for (let i = 0; i < files.length; i++) {
      this.setState((prevState, prevProps) => ({
        openAddFiles: false,
        uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]],
        showFileUploadDialog: true,
      }));
    }
  };

  _onSuccess = (fileName, data) => {
    let files = this.state.uploadedFilesInfo;
    files = files.map((file) => {
      if (file.name == fileName) {
        file.s3Info = data;
        file.uri = data.Location;
      }
      return file;
    });
    let attachment = files[0];
    this.setState(
      (prevState) => {
        return {
          uploadedFilesInfo: [],
          showFileUploadDialog: false,
        };
      },
      () => {
        this.sendAttachment(attachment);
      }
    );
  };

  removeAttachment = (attachment) => {
    let uploadedFilesInfo = this.state.uploadedFilesInfo.filter(
      (a) => a.name != attachment.name
    );
    this.setState({ uploadedFilesInfo });
  };

  showAttachment = (a) => {
    if (a.uri) {
      this.setState({
        showAttachment: true,
        //  selectedAttachment: a
      });
    }
  };

  getView = (attachment) => {
    return (
      <div className="ma-attachment">
        <div>
          <div
            style={{ width: "100%", overflow: "hidden" }}
            onClick={() => {
              this.showAttachment(attachment);
            }}
          >
            <div style={{ marginLeft: "20px" }}>
              <p>
                {" "}
                {attachment.name}{" "}
                {attachment.progressStatus < 100 && (
                  <ProgressBar
                    style={{ height: "6px" }}
                    value={attachment.progressStatus}
                  ></ProgressBar>
                )}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  componentWillReceiveProps(newProps) {
    let { sort, limit, page, search,selectedDoubt } = this.state;
    if (!this.state.selectedDoubt && newProps.selectedDoubt) {
      this.setState(
        {
          selectedDoubt: newProps.selectedDoubt,
          isResponded: false,
          stopConversation: false,
          openMore: false,
          openAddFiles: false,
          uploadedFilesInfo: [],
          showFileUploadDialog: false,
        },
        () => {
          this.getConversations(
            newProps.selectedDoubt._id,
            sort,
            limit,
            page,
            search,
            true
          );
        }
      );
    }

    if (this.state.currentTab !== newProps.currentTab) {
      this.setState({
        selectedDoubt: newProps.selectedDoubt,
        isResponded: false,
        stopConversation: false,
        openMore: false,
        openAddFiles: false,
        uploadedFilesInfo: [],
        showFileUploadDialog: false,
      });
    } else if (
      this.state.selectedDoubt &&
      newProps.selectedDoubt &&
      this.state.selectedDoubt._id !== newProps.selectedDoubt._id
    ) {
      console.log("333333333333");

      this.setState(
        {
          selectedDoubt: newProps.selectedDoubt,
          stopConversation: false,
          isResponded: false,
          openMore: false,
          openAddFiles: false,
          uploadedFilesInfo: [],
          showFileUploadDialog: false,
        },
        () => {
          this.getConversations(
            newProps.selectedDoubt._id,
            sort,
            limit,
            page,
            search,
            true
          );
        }
      );
    }

    if (newProps.currentSocketMessage) {
      console.log("444444444444");

      if (
        this.state.currentSocketMessage.eventUID !=
        newProps.currentSocketMessage.eventUID
      ) {
        this.setState(
          {
            currentSocketMessage: newProps.currentSocketMessage,
          },
          () => {
            this.onMessageRecieve(newProps.currentSocketMessage);
          }
        );
      }
    }
  }

  openImageModal = (file) => {
    this.setState((prev) => ({
      ...prev,
      showImagePop: true,
      attachmentURI: file?.uri,
    }));
  };

  handleTicketStatusSelectionChange = (val) => {
    console.log("valval", val)
    this.setState((prev) => ({
      ...prev,
      selectTicketStatus: val?.target?.value,
    }));
  };

  handleLevelSelectionChange = (val) => {
    this.setState((prev) => ({ ...prev, selectedLevel: val?.target?.value }));
  };

  saveLevel = () => {
    if(this.state.selectedLevel>=1){
      let payload = {
        action: "changeTicketLevel",
        data: {
          doubtId: this.state.selectedDoubt._id,
          from: this.props.discussionUser._id,
          to: this.state.selectedDoubt.askedBy._id,
          level: this.state.selectedLevel
        },
        user: this.props.discussionUser._id,
        beacon: uuidv4(),
      };
      this.setState((prev) => ({
        levelModal: false,
        selectedLevel: null,
        selectedDoubt: { ...prev.selectedDoubt, ticketLevel: this.state.selectedLevel }
      }));
      this.socketSend(payload);
    }
  };

  saveDeadlineDate = () => {
    if(this.state.selectedDeadlineDate){
      let payload = {
        action: "changeTicketDeadlineDate",
        data: {
          doubtId: this.state.selectedDoubt._id,
          from: this.props.discussionUser._id,
          to: this.state.selectedDoubt.askedBy._id,
          deadlineDate: this.state.selectedDeadlineDate
        },
        user: this.props.discussionUser._id,
        beacon: uuidv4(),
      };
      // this.setState((prev)=>({
      //   deadlineModal: false,
      //   selectedDeadlineDate: null,
      //   selectedDoubt: { ...prev.selectedDoubt, deadlineDate: this.state.selectedDeadlineDate }
      // }));
      this.setState(prevState => ({
        deadlineModal: false,
        selectedDeadlineDate: null,
        selectedDoubt: {
          ...prevState.selectedDoubt,
          expectedDeadline: this.state.selectedDeadlineDate
        },
      }))
      this.socketSend(payload);
      this.props.onCallRefreshTicketList(true)
    }
  };

  render() {
    const {
      selectedDoubt,
      conversations,
      statusModal,
      selectedStatus,
      selectedLevel,
      levelModal,
      deadlineModal,
      selectedDeadlineDate,
      selectTicketStatus,
    } = this.state;
    console.log("this.state.selectedDoubt", this.state.selectedDoubt)

    const items = [];
    if (
      !(
        (this.state.selectedDoubt && this.state.selectedDoubt.isReported) ||
        this.state.stopConversation
      ) &&
      this.state.selectedDoubt &&
      this.state.selectedDoubt.isAssigned
    ) {
      items.push(
        {
          template: (
            <span
              className="ma-pointer"
              onClick={(event) => {
                this.requestToCloseDoubt();
                this.menu.toggle(event);
              }}
            >
              {" "}
              Request to close this Ticket
            </span>
          ),
        },
        {
          template: (
            <span
              className="ma-pointer"
              onClick={(event) => {
                this.requestToJoinMeeting();
                this.menu.toggle(event);
              }}
            >
              Request to Join the Live Call
            </span>
          ),
        },
        {
          template: (
            <span
              className="ma-pointer"
              onClick={(event) => {
                this.setState((prev) => ({ ...prev, statusModal: true }));
                this.menu.toggle(event);
              }}
            >
              Update the status
            </span>
          ),
        },
        {
          template: (
            <span
              className="ma-pointer"
              onClick={(event) => {
                this.setState((prev) => ({ ...prev, levelModal: true }));
                this.menu.toggle(event);
              }}
            >
              Update level
            </span>
          ),
        },
        {
          template: (
            <span
              className="ma-pointer"
              onClick={(event) => {
                this.setState((prev) => ({ ...prev, deadlineModal: true }));
                this.menu.toggle(event);
              }}
            >
              Update expected deadline
            </span>
          ),
        }
      );
    }
    return (
      <>
        <div className="conversation-c">
          <>
            {!selectedDoubt ? (
              <div className="select-profile-c">
                {" "}
                <div className="select-profile">
                  <ChatIcon height={200} width={200} color="var(--theme-45)" />
                  <p className="select-profile-txt">Select any ticket</p>
                </div>{" "}
              </div>
            ) : (
              <>
                <div>
                  {selectedDoubt && (
                    <div className="c-top-bar">
                      <div className="chat-topnar">
                        <div className="ma-1">
                          <img
                            src={getProfileURL(selectedDoubt?.askedBy?.photo)}
                            className="prfl-img"
                          />
                        </div>
                        <div className="ma-1 ma-ml10">
                          <p className="prfl-name">
                            {selectedDoubt?.askedBy?.name}
                          </p>
                          {/* <p className="prfl-grade"  >{'Class XII , Engineering (CBSC)'}</p> */}
                        </div>
                        {this?.props?.userInfo?.isSuperAdmin && (
                          <div className="ma-right">
                            {!(
                              this.state.selectedDoubt.isReported ||
                              this.state.stopConversation
                            ) &&
                              this.state.selectedDoubt.isAssigned && (
                                <span>
                                  <i
                                    className="pi pi-ellipsis-v ma-pointer"
                                    //onClick={this.onMoreClick}
                                    onClick={(event) => this.menu.toggle(event)}
                                  />
                                </span>
                              )}

                            <Menu
                              className="answer-menu"
                              model={items}
                              popup
                              ref={(el) => (this.menu = el)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="ma-clearFix"></div>
                    </div>
                  )}
                  <div
                    id="chat-window"
                    ref={this.chatWidowRef}
                    className="chat-window ma-scroll-vr-smoke"
                    style={
                      this.state.rows > 1
                        ? {
                          minHeight: this.state.style,
                          maxHeight: this.state.style,
                        }
                        : {}
                    }
                  >
                    {this.state.conversations.map((c, i) => {
                      return (
                        <div key={i.toString()}>
                          {c?.ticket ? (
                            <div
                              className={`ticket-window ${this?.props?.userInfo?.isSuperAdmin
                                  ? "ma-left1 ma-left-b"
                                  : "ma-right"
                                }`}
                              style={{ marginBottom: 15 }}
                            >
                              <p className="date__time">
                                {moment(c?.createdAt).format(
                                  "DD MMM YYYY, hh:mm:a"
                                )}
                              </p>
                              <div>
                                <div>
                                  <b className="desc__title">
                                    Description of the ticket:
                                  </b>{" "}
                                  <span>{c?.ticket?.description}</span>
                                </div>
                                <br />
                                <span>
                                  <b>Email Id:</b> {c?.ticket?.emailId}
                                </span>
                                <br />
                                <span>
                                  <b>Phone Number:</b> {c?.ticket?.phoneNumber}
                                </span>
                                <br />
                                <span>
                                  <b>Url:</b>{" "}
                                  <a href={c?.ticket?.url}>{c?.ticket?.url}</a>
                                </span>
                                <br />
                                <div className="attachments">
                                  <b className="attachments__title">
                                    Attachments:
                                  </b>{" "}
                                  {c?.ticket?.uploadedFilesInfo?.map((file) => {
                                    return (
                                      <>
                                        <div className="attachment__file">
                                          <a>{file?.name}</a>
                                          <button
                                            className="view__attachment"
                                            onClick={() =>
                                              this.openImageModal(file)
                                            }
                                          >
                                            <i className="pi pi-eye"></i>
                                          </button>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <Common
                              discussionUser={this.props.discussionUser}
                              conversation={c}
                              deleteMessage={this.deleteMessage}
                            />
                          )}
                          <div className="ma-clearFix"></div>
                        </div>
                      );
                    })}
                    <div ref={this.messageEndRef} />
                  </div>
                  {/* {(this.state.selectedDoubt.isReported || this.state.stopConversation) ? <div className="closed-chat">
                                        {this.state.selectedDoubt.isReported ? <p>Doubt is reported</p> : <p className="">Chat is closed</p>}
                                    </div> */}
                  {this.props.currentTab === 2 ? (
                    <div className="closed-chat">
                      <p className="">Chat is closed</p>
                    </div>
                  ) : (
                    <div className="p-grid ma-no-pm chat-typing">
                      <div className="p-col-12">
                        <div style={{ width: "100%", overflow: "hidden" }}>
                          <div style={{ width: "40px", float: "left" }}>
                            <Authorizer
                              permId={
                                PERMISSIONS.ASK_A_DOUBT_GENERAL_UPLOAD_ATTACHMENT
                              }
                            >
                              <span
                                className="ma-pointer"
                                onClick={(event) => {
                                  this.state.selectedDoubt.isAssigned &&
                                    this.menu2.toggle(event);
                                }}
                              >
                                <AddIcon
                                  disabled={this.state.selectedDoubt.isAssigned}
                                  width={30}
                                  height={30}
                                  color="var(--primary-color)"
                                  style={{ marginTop: "4px" }}
                                />
                              </span>
                            </Authorizer>
                            <Menu
                              className="answer-menu"
                              model={[
                                {
                                  template: (
                                    <>
                                      <FileUpload
                                        id={"imagesd"}
                                        multiple={false}
                                        onProgress={this._onProgress}
                                        onSucess={this._onSuccess}
                                        Reset={this.state.Reset}
                                        maxFileSize={
                                          StaticConstants.MAX_FILE_SIZE
                                        }
                                        onError={this._onFilsUploadError}
                                        accept={"imagevideo"}
                                        title={"add image or video"}
                                        labelStyles={{ display: "block" }}
                                        menuRef={this.menu2}
                                        afterFilesUploaded={
                                          this._afterFilesUploaded
                                        }
                                      >
                                        <span
                                          className="ma-pointer"

                                        // onClick={(event) => { this.menu2.toggle(event) }}
                                        >
                                          <img
                                            className="add-file-attach ma-pointer"
                                            src="./images/Photo_video_icon.svg"
                                          />{" "}
                                          Photos & Videos
                                        </span>
                                      </FileUpload>
                                    </>
                                  ),
                                },
                                {
                                  template: (
                                    <>
                                      {
                                        <FileUpload
                                          id={"pdfss"}
                                          multiple={false}
                                          onProgress={this._onProgress}
                                          onSucess={this._onSuccess}
                                          Reset={this.state.Reset}
                                          maxFileSize={
                                            StaticConstants.MAX_FILE_SIZE
                                          }
                                          onError={this._onFilsUploadError}
                                          accept={"pdfs"}
                                          title={"add pdf"}
                                          menuRef={this.menu2}
                                          labelStyles={{ display: "block" }}
                                          afterFilesUploaded={
                                            this._afterFilesUploaded
                                          }
                                        >
                                          <span
                                            className="ma-pointer"
                                          //  onClick={() => {
                                          //     setTimeout(() => {
                                          //         this.setState({ openAddFiles: false })
                                          //     }, 5000);
                                          // }}
                                          //  onClick={(event) => { this.menu2.toggle(event) }}
                                          >
                                            <img
                                              className="add-file-attach ma-pointer"
                                              src="./images/PDF_Doc_icon.svg"
                                            />{" "}
                                            PDF's & Documents
                                          </span>
                                        </FileUpload>
                                      }
                                    </>
                                  ),
                                },
                              ]}
                              popup
                              ref={(el) => (this.menu2 = el)}
                            />
                          </div>
                          <div style={{ marginLeft: "40px", display: "flex" }}>
                            <textarea
                              disabled={
                                this.state.selectedDoubt.isReported ||
                                this.state.stopConversation ||
                                !(
                                  this.state.selectedDoubt &&
                                  this.state.selectedDoubt.isAssigned
                                )
                              }
                              className="chat-textarea"
                              //rows="1"
                              rows={this.state.rows}
                              value={this.state.message}
                              onChange={this.onTextChange}
                            />
                            {(this.state.selectedDoubt &&
                              this.state.selectedDoubt.isAssigned &&
                              this.props.discussionUser._id ==
                              this.state.selectedDoubt.assignedTo) ||
                              this.state.isResponded ? (
                              <>
                                {!(
                                  this.state.selectedDoubt.isReported ||
                                  this.state.stopConversation
                                ) && (
                                    <span
                                      className="ma-pointer"
                                      onClick={this.sendMessage}
                                    >
                                      <SendIcon
                                        width={34}
                                        height={34}
                                        color="var(--primary-color)"
                                      />
                                    </span>
                                  )}
                              </>
                            ) : (
                              <>
                                <Authorizer
                                  permId={
                                    PERMISSIONS.ASK_A_DOUBT_GENERAL_ATTEMPT_DOUBT
                                  }
                                >
                                  <div className="respond-now-w">
                                    <button
                                      disabled={this.state.isLoading}
                                      className="respond-now"
                                      onClick={() => {
                                        this.respondToDoubt();
                                      }}
                                    >
                                      Respond Now
                                    </button>
                                  </div>
                                </Authorizer>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <Dialog
                    blockScroll={true}
                    visible={this.state.showAssignedDialog}
                    onHide={() => {
                      this.setState({ showAssignedDialog: false });
                    }}
                    closable={false}
                    showHeader={true}
                    footer={() => {
                      return (
                        <div>
                          <Button
                            label="Ok"
                            className="ma-m-lr10"
                            onClick={this.onDialogClose}
                          />
                        </div>
                      );
                    }}
                  >
                    <span className="warning-icon">
                      <WarningIcon width={"100%"} height={"100%"} />
                    </span>
                    <p className="warning-title">
                      It is already assigned to some other teacher.
                    </p>
                  </Dialog>
                  <Dialog
                    blockScroll={true}
                    visible={this.state.showFileUploadDialog}
                    closable={false}
                    showHeader={true}
                  // footer={() => {
                  //     return (<div>
                  //         <Button label="Ok" className='ma-m-lr10'
                  //             onClick={this.onDialogClose}
                  //         />
                  //     </div>)
                  // }}
                  >
                    <p className="warning-title">
                      Please wait the file is Uploading....
                    </p>
                    {this.state.uploadedFilesInfo &&
                      this.state.uploadedFilesInfo.length
                      ? this.getView(this.state.uploadedFilesInfo[0])
                      : null}
                  </Dialog>
                </div>
              </>
            )}
          </>
        </div>
        <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
        {this.state.showReport && (
          <ReportDialog
            open={true}
            onSubmit={this.reportDoubt}
            onClose={() => {
              this.setState({ showReport: false });
            }}
          />
        )}
        {this.state.isLoading && <LoadingComponent />}
        <Dialog
          blockScroll={true}
          visible={this.state.showImagePop}
          onHide={() => {
            this.setState({ showImagePop: false, attachmentURI: "" });
          }}
        >
          <img src={this.state.attachmentURI} className="popup-img" />
        </Dialog>

        <Dialog
          visible={statusModal}
          onHide={() => {
            this.setState({ statusModal: false, selectTicketStatus: null });
          }}
          header="UPDATE THE TICKET STATUS"
          footer={
            <>
              <Button label="Save" onClick={this.changeTicketStatus} />
            </>
          }
          style={{ width: "30%" }}
        >
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 20,
              }}
            >
              <Dropdown
                options={statusOPtions}
                value={selectTicketStatus}
                placeholder="status"
                onChange={this.handleTicketStatusSelectionChange}
                style={{ width: 200 }}
              />
            </div>
          </>
        </Dialog>

        <Dialog
          visible={levelModal}
          onHide={() => {
            this.setState({ levelModal: false, selectedLevel: null });
          }}
          header="UPDATE LEVEL"
          footer={
            <>
              <Button label="Save" onClick={this.saveLevel} />
            </>
          }
          style={{ width: "30%" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Dropdown
              options={levelOPtions}
              value={selectedLevel}
              placeholder="Level"
              onChange={this.handleLevelSelectionChange}
              style={{ width: 200 }}
            />
          </div>
        </Dialog>
        <Dialog
          visible={deadlineModal}
          onHide={() => {
            this.setState({ deadlineModal: false, selectedDeadlineDate: null });
          }}
          header="UPDATE EXPECTED DEADLINE"
          footer={
            <>
              <Button label="Save" onClick={this.saveDeadlineDate} />
            </>
          }
          style={{ width: "30%" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Calendar
              showIcon
              value={
                selectedDeadlineDate ? new Date(selectedDeadlineDate) : null
              }
              dateFormat="dd-mm-yy"
              placeholder="select Date"
              onChange={(e) =>
                this.setState((prev) => ({
                  ...prev,
                  selectedDeadlineDate: new Date(e.value).getTime(),
                }))
              }
            />
          </div>
        </Dialog>
      </>
    );
  }
}

const mapStatesToProps = (state) => ({
  discussionUser:
    state.currentUserPerms &&
    state.currentUserPerms.discussionUser &&
    state.currentUserPerms.discussionUser,
  userInfo:
    state.currentUserPerms && state.currentUserPerms.userInfo
      ? state.currentUserPerms.userInfo
      : {},
});

export default connect(mapStatesToProps, {})(Conversation);
