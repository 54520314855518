import * as React from "react";
import { S3_UPLOAD_CONFIG, StaticConstants } from './../../../constants'
import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';
import uuidv4 from 'uuid/v4';
import { getServerTime } from "../../../utile";
import { getInfo } from "../../login/s3login";


/* 
const s3Bucket = new AWS.S3({
    accessKeyId: S3_UPLOAD_CONFIG.accessKeyId,
    secretAccessKey: S3_UPLOAD_CONFIG.secretAccessKey,
    region: S3_UPLOAD_CONFIG.region,

});
 */
class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadedFileInfo: [],
            uploadedFilesInfo: [],
            accept: this.props.accept,
            maxFileSize: this.props.maxFileSize ? this.props.maxFileSize : 1073741824
        }

        this.reactS3Client = new S3(S3_UPLOAD_CONFIG);
    }

    uploadfile = async(fileName, file, folderName, onSucess) => {
        const params = {
            Bucket: S3_UPLOAD_CONFIG.bucketName,
            Key: folderName + fileName,
            Body: file,
            ContentType: file.type
        };
        
        await getInfo()

        const s3Bucket = new AWS.S3()

        return s3Bucket.upload(params, function (err, data) {

            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }
            onSucess(file.name, data);
            return true;
        });
    }


    onSucess = (fileName, data) => {
        this.props.onSucess(fileName, data)
    }


    onProgress = (some) => {
        this.props.onProgress(some);
    }

    onChangeHandler = (event, onSucess, onProgress) => {
        if (this.props.menuRef) {
            this.props.menuRef.toggle();
        }


        let canUpload = true;
        let errMsg = '';
        if (event.target.files !== null && event.target.files !== undefined && event.target.files.length > 0) {
            for (let i = 0; i < event.target.files.length; i++) {
                /// 300mb == 314572800 
                /// 200mb == 209715200
                if (event.target.files[i].size > this.state.maxFileSize) {
                    canUpload = false;
                    errMsg = `Only ${this.state.maxFileSize / 1048576}MB files are allowed.`
                }
                if (!this.getAcceptFiles(this.props.accept).includes(event.target.files[i].type)) {
                    canUpload = false;
                    errMsg = `Only ${this.getAcceptType(this.props.accept)} are allowed`;
                }

                event.target.files[i].id = uuidv4();
            }
            // if (event.target.files !== null && event.target.files !== undefined && event.target.files.length > 0) {
            if (canUpload) {
                this.props.afterFilesUploaded(event.target.files);
                for (let i = 0; i < event.target.files.length; i++) {
                    let needToUpload = true;
                    if (this.state.uploadedFileInfo.length > 0) {
                        for (let j = 0; j < this.state.uploadedFileInfo.length; j++) {
                            if (event.target.files[i].name === this.state.uploadedFileInfo[j].data.originalName) {
                                needToUpload = false;
                            }
                        }
                    }
                    if (needToUpload) {
                        let file = event.target.files[i];
                        let newFileName = getServerTime().toISOString() + file.name;

                        let fileUpload = {
                            id: file.id,
                            name: file.name,
                            newFileName: newFileName,
                            size: file.size,
                            type: file.type,
                            timeReference: 'Unknown',
                            progressStatus: 0,
                            displayName: file.name,
                            status: 'Uploading..',
                        }


                        this.uploadfile(newFileName, file, S3_UPLOAD_CONFIG.dirName, onSucess)
                            .on('httpUploadProgress', function (progress) {
                                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                                if (progressPercentage < 100) {
                                    fileUpload.progressStatus = progressPercentage;

                                } else if (progressPercentage == 100) {
                                    fileUpload.progressStatus = progressPercentage;

                                    fileUpload.status = "Uploaded";

                                }
                                onProgress(fileUpload);
                            })
                    }
                }
            } else {
                this.props.onError(errMsg);
            }
        }
        // if (this.props.ResetOnSelect) {
        //     event.target.value = null
        // }
        // 
    }

    componentWillReceiveProps(newProps) {
        if (newProps.Reset === true) {
            this.setState({
                uploadedFileInfo: [],
                uploadedFilesInfo: []
            });
        }
    }

    getAcceptFiles(accept) {
        if (accept) {
            if (accept == "images") {
                //return 'image';
                return ['image/jpeg', 'image/png', 'image/jpg'];
            } else if (accept == "audios") {
                return ['audio/mpeg'];
            } else if (accept == "videos") {
                return ['video/mp4', 'video/quicktime'];
            } else if (accept == "pdfs") {
                return ['application/pdf'];
            } else if (accept == "imagevideo") {
                return ['video/mp4', 'video/quicktime', 'image/jpg', 'image/jpeg', 'image/png']
            } else if (accept == "ivp") {
                return ['video/mp4', 'video/quicktime', 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf']
            } else {
                return [];
            }
        } else {
            return []
        }
    }

    getAcceptString(accept) {
        if (accept) {
            if (accept == "images") {
                return 'image/*'
            } else if (accept == "audios") {
                return 'audio/*'
            } else if (accept == "videos") {
                return 'video/*'
            } else if (accept == "pdfs") {
                return 'application/pdf'
            } else if (accept == "imagevideo") {
                return 'image/*,video/*'
            } else if (accept == "ivp") {
                return 'video/mp4,video/quicktime,image/jpg,image/jpeg,image/png,application/pdf'
            } else {
                return 'audio/*,video/*,image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint';
            }
        } else {
            return 'audio/*,video/*,image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint';
        }
    }

    getAcceptType(accept) {
        if (accept) {
            if (accept == "images") {
                return 'images'
            } else if (accept == "audios") {
                return 'audios'
            } else if (accept == "videos") {
                return 'videos'
            } else if (accept == "pdfs") {
                return 'pdf'
            } else if (accept == "imagevideo") {
                return 'image, video'
            } else if (accept == "ivp") {
                return 'mp4, quicktime, jpg, jpeg, png, pdf'
            } else {
                return 'audios, videos, images, pdfs, excel, powerpoint';
            }
        } else {
            return 'all';
        }
    }

    render() {
        return (
            <>
                <label style={this.props.labelStyles} htmlFor={this.props.id} title={this.props.title ? this.props.title : 'add'} >
                    {this.props.children}
                </label>
                <input type="file" hidden={true} accept={this.getAcceptString(this.props.accept)} name="files" id={this.props.id} multiple={this.props.multiple} className="ma-file" onChange={(event) => this.onChangeHandler(event, this.onSucess, this.onProgress)} />
            </>
        );
    }
}

export default FileUpload;
